.textArea {
    grid-column: 1 / 3;
    height: 210px;
    border-radius: 10px;
    border: 1px solid #C0C5CC;
    background: #FFF;
    padding: 21px 34px;
    resize: none;

    color: #C0C5CC;

    font-family: Montserrat, "sans-serif";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 24.2px */
}

.textArea::placeholder {
    color: #C0C5CC;
}
