
.servicesSection {
    background-color: var(--background-color);
}

.ServicesWrapper {
    display: flex;
    flex-direction: column;
    min-height: 1081px;
    justify-content: center;
}

.cardsContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
}
