* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

input,
button,
textarea,
select {
    font: inherit;
}

html{scroll-behavior:smooth}
