.header {
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    background-color: #FFFFFF;
    z-index: 100;
    background-color: hsla(0, 0%, 100%, .8);
    backdrop-filter: blur(10px);
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 108px;
}

.header__menu {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
}

.header__logo {
    cursor: pointer;
    padding: 5px 9px;
}

.header__logo h1 {
    color: #000;
    font-family: FONTSPRING_DEMO_Mongoose_Medium_Regular, sans-serif;
    font-size: 50.73px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 50.73px */
}

.header__menu li a {
    margin-right: 3rem;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.40);
    font: var(--font-m-monsterrat);
    font-style: normal;
    font-weight: 500;
}

.header__menu li a:hover {
    color: var(--secondary-color);
}

.logoContainer {
    display: flex;
    align-items: center;
}

.logoText {
    margin-left: 14px;
}

