
.Card {
    display: flex;
    width: 24%;
    height: 618px;
    /*grid-auto-rows: 1fr;*/
    flex-direction: column;
    /*justify-content: space-between;*/
    align-items: center;
    background-color: #FFFFFF;
    border-radius: 30px;
}

.imgContainer {
    width: 100%;
    margin-bottom: 32px;
}

.img {
    border-radius: 30px;
    width: 100%; /* Картинка занимает всю ширину контейнера */
    height: 100%; /* Автоматическая высота */
    object-fit: cover;

}

.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.header {
    color: #000;
    text-align: center;
    font-family: "FONTSPRING_DEMO_Mongoose_Medium", sans-serif;
    font-size: 36.232px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
}

.header span {
    font-family: NotoSans, sans-serif;
}

.text {
    color: rgba(0, 0, 0, 0.50);
    text-align: center;
    font-family: NotoSans, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
}
