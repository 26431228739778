.mainSection {
    margin-top: 24px;
    background-color: var(--background-color);
}

.main-menu__item a:hover {
    color: #FF5C00;
}

.mainWrapper {
    display: flex;
    align-items: center;
    min-height: 848px;
}

.mainWrapper__info {
    width: 916px;
    margin-right: 124px;
}




