.missionWrapperInfo {
    width: 916px;
}

.missionWrapper {
    display: flex;
    flex-direction: column;
    min-height: 1300px;
    justify-content: center;
}

.missionWrapperImage {
    display: flex;
    //justify-content: space-between;
}

.imgMission {
    flex: 1;
    //display: inline-block;
    //flex-grow: 1;
    //width: 50%;
    //flex: 1 1 0px;
}


/*.missionWrapper__margin--p {*/
/*    margin-bottom: 90px;*/
/*}*/
