
.WhySection {

}

.WhyWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 848px;
}

.infoContainer {
    margin-right: 62px;
    width: 35%;
}

.imgContainer {

}

.image {
    height: 449px;
}
