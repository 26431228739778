
.Title {
    /*color: #000;*/
    /*font: var(--font-xl), "sans-serif";*/
    /*font-style: normal;*/
    /*font-weight: 700;*/

    /*color: #000;*/

    font-family: FONTSPRING_DEMO_Mongoose_Medium_Regular, "sans-serif";
    font-size: 68px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%; /* 74.8px */
}
