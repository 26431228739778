.careerSection {
    background-color: var(--background-color);
}

.careerWrapper {
    display: flex;
    flex-direction: column;
    min-height: 1100px;
    justify-content: center;
}

.fs26 {
    font-size: 26px;
}
