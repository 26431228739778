.downContainer {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
}

.arrowButton {
    border-radius: 56px;
    background: var(--secondary-color);
    display: flex;
    width: 46px;
    height: 46px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    transition: all .2s ease-in-out;
}

.downText {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #000;
    font-family: var(--font-m), "sans-serif";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
    text-transform: uppercase;
    transition: color .2s ease-in-out;
}

.downContainer:hover .arrowButton {
    transform: scale(1.1);
}


.downContainer:hover .downText {
    color: #C0C5CC;
}
