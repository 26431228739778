.footer {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    min-height: 187px;
    background-color: #000;
}

.footerSocials {
    border-bottom: 1px solid #1A1A1A;
    padding-bottom: 19px;
    z-index: 2;
}

.footerSocialCookie {
    cursor: pointer;
    color: #9C9C9C;

    font-family: var(--font-m), "sans-serif";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 18px */
}

.footerSocialCookie:hover {
    color: #858585;
}

.footerCopyright {
    color: #414141;

    font-family: NotoSans, "sans-serif";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    white-space: pre-line;
}

.footerSocial {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.footerLink {
    margin-right: 25px;
}

.footerAllrights {
    margin-top: 19px;
    z-index: 2;
}

.footerAllrightsRights {
    color: #414141;

    font-family: NotoSans, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 16px */
}

.footerAllrightsWrapper {
    display: flex;
    justify-content: space-between;
}

.closeImg {
    cursor: pointer;
    position: absolute;
    top: 26px;
    right: 26px;
}

.cookieP {
    color: #B1B1B1;

    font-family: Montserrat, "sans-serif";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%; /* 28.6px */
}

.opacity {
    position: absolute;
    transition: ease-in-out .5s;
    opacity: 0;
}

.opacity:hover {
    opacity: 1;
    transition: opacity .5s ease-in-out;
}
