@import "./variables/global.css";
@import "./reset.css";

@font-face {
    font-family: "Montserrat";
    src: url('./../../shared/assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./../../shared/assets/fonts/Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url('./../../shared/assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "NotoSans";
    src: url('./../../shared/assets/fonts/NotoSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "NotoSans";
    src: url('./../../shared/assets/fonts/NotoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "FONTSPRING_DEMO_Mongoose_Medium_Regular";
    src: url('./../../shared/assets/fonts/FontsFree-Net-Fontspring-DEMO-mongoose-medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "FONTSPRING_DEMO_Mongoose_Medium";
    src: url('./../../shared/assets/fonts/Fontspring-DEMO-mongoose-medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}

body {
    margin: 0;
    font: var(--font-l);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 100%;
    overflow-x: hidden;
}

.container {
    margin: 0 auto;
}

.container--main {
    max-width: 1420px;
    width: 91.5vw;
}

@media screen and (max-width: 1700px) {
    .container--main {
        width: calc(100% - 250px);
    }
}

.App {
    padding-top: 75px;
}

.img {
    width: 100%;
    border-radius: 30px;
}

.hidden {
    visibility: hidden;
}

.invalidFeedback {
    position: absolute;
    color: red;
    font-family: Montserrat, "sans-serif";
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
}
